import { differenceInDays, formatDistanceStrict } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { isPast } from 'date-fns/isPast'
import getConfig from 'next/config'
import { forwardRef, memo, useContext, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import { showRegisterButton } from '@nx/fire/auction-hero'
import { FireLink } from '@nx/fire/link'
import { FireTooltip } from '@nx/fire/tooltip'
import {
  AuctionBiddingEngine,
  AuctionType,
  Brand,
  LotStatus,
  MaintenanceMode,
} from '@nx/global-types'
import { buildLotPath } from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'
import { useUserInteraction } from '@nx/utils/google-tag-manager'

import { useUserRegistrations } from '@web/helpers/api/user/useUserRegistrations'

import { ModalsDispatchContext } from '../ModalManager/ModalContext'
import {
  LotCardContainer,
  LotCardMainContainer,
  StyledActionButton,
  StyledActionContainer,
  StyledAttentionIcon,
  StyledBidNowHint,
  StyledBidNowHintDescription,
  StyledLotCardSeparator,
} from './LotCard.styles'
import translate from './LotCard.translate.json'
import { LotCardItemProps } from './LotCard.types'
import { LotCardCompact } from './LotCardCompact'
import LotCardAuctionMobility from './components/LotCardAuctionMobility'
import LotCardImage from './components/LotCardImage'
import { LotCardPrice } from './components/LotCardPrice'
import LotCardStatus from './components/LotCardStatus'
import LotCardTitle from './components/LotCardTitle'
import { useManageRegisterModal } from './useManageRegisterModal'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export const LotCard = memo(
  forwardRef<HTMLDivElement, LotCardProps>(
    (
      {
        variant = 'normal',
        lot,
        isShowingLotId,
        lotClickCallback,
        showAMWidget,
        isFollowButtonDisplayed,
        isBackTopShown,
        isUserPurchase = false,
        showPrice = true,
        showBidNow,
        showRegisterNow,
        userHasAnAddress,
        lotTracking,
        ...props
      },
      ref
    ) => {
      const { data: flags } = useGetLaunchDarklyFlags(
        publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
      )
      const showRegisterNowButtonFF = !!flags?.show_register_now_in_lot_card

      const dispatch = useContext(ModalsDispatchContext)

      const trackingLabel = `${lot.auctionId}-${lot.isPreview ? lot.lotUniqueId : lot.lotId}`

      function showBidModal() {
        recordInteraction({
          category: lotTracking.category,
          action: 'bidNow.click',
          label: trackingLabel,
        })

        dispatch?.({
          type: 'OPEN',
          payload: {
            modal: 'BID_NOW',
            props: { algoliaLotData: lot, lotPageLink: href },
          },
        })
      }

      function showRegisterModal() {
        recordInteraction({
          action: 'registerNow.click',
          category: lotTracking.category,
          label: trackingLabel,
        })

        dispatch?.({
          type: 'OPEN',
          payload: {
            modal: 'REGISTER_NOW',
            props: {
              auctionId: lot.auctionId,
              lotUniqueId: lot.lotUniqueId,
              lotId: lot.lotId,
              isPreview: lot.isPreview,
              lotCardLocation: lotTracking.category,
              showBidModal,
            },
          },
        })
      }

      const { recordInteraction } = useUserInteraction()
      const { userRegistrations } = useUserRegistrations()
      const [showBidNowTooltip, setShowBidNowTooltip] = useState(false)

      let injectAMWidget = false
      let enableRegisterNow = false
      let enableBidNow = false
      let enableBidNowTooltip = false

      if (lot.biddingEngine === AuctionBiddingEngine.AM) {
        if (!showAMWidget) {
          const userRegisterToBid = userRegistrations.some(
            (registration) => registration.iSaleNo === lot.auctionId
          )

          const userApprovedToBid = userRegistrations.some(
            (registration) =>
              registration.iSaleNo === lot.auctionId &&
              registration.approvedToBid
          )
          const isBiddable =
            lot.auctionType === AuctionType.online
              ? lot.biddableFrom
                ? isPast(lot.biddableFrom?.datetime)
                : false
              : !lot.isExhibition

          enableRegisterNow =
            showRegisterNowButtonFF &&
            !!showRegisterNow &&
            !userRegisterToBid &&
            !!userHasAnAddress &&
            showRegisterButton({
              isExhibition: lot.isExhibition,
              auctionType: lot.auctionType,
              auctionStatus: lot.auctionStatus,
              rowId: lot.lotId,
              biddingStatus: lot.biddingStatus,
            })

          if (!enableRegisterNow) {
            const enableBidCheck =
              isBiddable &&
              !showAMWidget &&
              !lot.isPreview &&
              !!lot.lotAMId &&
              lot.isToBeSold &&
              userRegisterToBid

            if (enableBidCheck) {
              if (userApprovedToBid) {
                enableBidNow = !!showBidNow
              } else {
                enableBidNowTooltip =
                  showRegisterNowButtonFF && !!showRegisterNow
              }
            }
          }
        } else {
          injectAMWidget =
            lot.status.toUpperCase() !== LotStatus.withdrawn &&
            !!lot.lotAMId &&
            publicRuntimeConfig.MAINTENANCE_MODE !== MaintenanceMode.partial
        }
      }

      useManageRegisterModal({
        openRegistrationModal: showRegisterModal,
        registerNowCanBeOpened: enableRegisterNow,
        lotUniqueId: lot.lotUniqueId,
      })

      const brandUrl: Record<string, string> = {
        [Brand.cars]: publicRuntimeConfig.CARS_BASE_URL,
      }
      const href = buildLotPath({
        auctionId: lot.auctionId,
        lotId: lot.isPreview ? lot.lotUniqueId : lot.lotId,
        biddingEngine: lot.biddingEngine,
        lotName: lot.title,
        isPreview: lot.isPreview,
        isExhibition: lot.isExhibition,
        domain: brandUrl[lot.brand],
        url: lot.url,
        extraUrlParams: lot.extraUrlParams,
      })

      let nowDate: Date | undefined = undefined
      let auctionEndDate: Date | undefined = undefined

      if (lot.hammerTime?.timezone) {
        nowDate = toZonedTime(new Date(), lot.hammerTime.timezone.iana)
        auctionEndDate = toZonedTime(
          lot.hammerTime.datetime,
          lot.hammerTime.timezone.iana
        )
      }

      function handleLotClick() {
        recordInteraction({
          action: 'lot-card.click',
          category: lotTracking.category,
          label: trackingLabel,
        })

        lotClickCallback?.()
      }

      return variant === 'compact' ? (
        <LotCardCompact
          image={lot.image}
          lotItemId={lot.lotItemId}
          lotIdNumber={lot.lotIdNumber}
          lotId={lot.lotId}
          biddingEngine={lot.biddingEngine}
          symbols={lot.symbols}
          title={lot.title}
          heading={lot.heading}
          status={lot.status}
          isResultPublished={lot.isResultPublished}
          isExhibition={lot.isExhibition}
          isPreview={lot.isPreview}
          isReferToDepartment={lot.isReferToDepartment}
          isWithoutReserve={lot.isWithoutReserve}
          currencySymbol={lot.currencySymbol}
          currencyISO={lot.currencyISO}
          hammerPrice={lot.hammerPrice}
          hammerPremium={lot.hammerPremium}
          estimateLow={lot.estimateLow}
          estimateHigh={lot.estimateHigh}
          GBPLowEstimate={lot.GBPLowEstimate}
          GBPHighEstimate={lot.GBPHighEstimate}
          auctionType={lot.auctionType}
          isShowingLotId={isShowingLotId}
          lotClickCallback={handleLotClick}
          isUserPurchase={isUserPurchase}
          href={href}
          showPrice={showPrice}
        />
      ) : (
        <LotCardMainContainer
          ref={ref}
          {...props}
          $isBackTopShown={isBackTopShown}
        >
          <LotCardContainer>
            <LotCardImage
              {...SmartlingAttributes.noTranslate}
              image={lot.image}
              lotItemId={lot.lotItemId}
              auctionId={lot.auctionId}
              title={lot.title}
              href={href}
              lotClickCallback={handleLotClick}
              isFollowButtonDisplayed={isFollowButtonDisplayed}
              biddingEngine={lot.biddingEngine}
            />
            <div>
              <FireLink href={href} onClick={handleLotClick}>
                <LotCardTitle
                  lotId={lot.lotId}
                  symbols={lot.symbols}
                  styledDescription={
                    lot.brand === Brand.carsOnline
                      ? text('onlineOnlyTitle', { title: lot.title })
                      : lot.styledDescription
                  }
                  title={lot.title}
                  heading={lot.heading}
                  isShowingLotId={isShowingLotId}
                  biddingEngine={lot.biddingEngine}
                  isExhibition={lot.isExhibition}
                  isPreview={lot.isPreview}
                />
                <LotCardStatus
                  status={lot.status}
                  isAmended={lot.isAmended}
                  isWithoutReserve={lot.isWithoutReserve}
                />
                {showPrice && (
                  <LotCardPrice
                    status={lot.status}
                    auctionType={lot.auctionType}
                    isResultPublished={lot.isResultPublished}
                    isExhibition={lot.isExhibition}
                    isReferToDepartment={lot.isReferToDepartment}
                    currencySymbol={lot.currencySymbol}
                    currencyISO={lot.currencyISO}
                    hammerPrice={lot.hammerPrice}
                    hammerPremium={lot.hammerPremium}
                    estimateLow={lot.estimateLow}
                    estimateHigh={lot.estimateHigh}
                    GBPLowEstimate={lot.GBPLowEstimate}
                    GBPHighEstimate={lot.GBPHighEstimate}
                    highlightWon={isUserPurchase}
                  />
                )}
              </FireLink>

              {injectAMWidget && (
                <LotCardAuctionMobility
                  lotAMId={lot.lotAMId}
                  auctionType={lot.auctionType}
                  lotClosingInString={
                    nowDate &&
                    auctionEndDate &&
                    differenceInDays(auctionEndDate, nowDate) > 0
                      ? text('closingTimer', {
                          numberOfDays: formatDistanceStrict(
                            nowDate,
                            auctionEndDate
                          ),
                        })
                      : undefined
                  }
                  hasHammerPrice={!!lot.hammerPrice}
                />
              )}
            </div>

            {enableRegisterNow ? (
              <StyledActionContainer>
                <StyledActionButton variant="ghost" onClick={showRegisterModal}>
                  {text('registerNow')}
                </StyledActionButton>
              </StyledActionContainer>
            ) : enableBidNowTooltip ? (
              <StyledActionContainer>
                <StyledBidNowHint>{text('bidNow')}</StyledBidNowHint>
                <FireTooltip
                  showTooltip={showBidNowTooltip}
                  tooltip={
                    <StyledBidNowHintDescription $level="S">
                      {text('bidNowHint')}
                    </StyledBidNowHintDescription>
                  }
                  onCloseClick={() => {
                    setShowBidNowTooltip(false)
                  }}
                  hideCloseButton
                >
                  <StyledAttentionIcon
                    onClick={() => setShowBidNowTooltip(true)}
                  />
                </FireTooltip>
              </StyledActionContainer>
            ) : (
              enableBidNow && (
                <StyledActionContainer>
                  <StyledActionButton variant="ghost" onClick={showBidModal}>
                    {text('bidNow')}
                  </StyledActionButton>
                </StyledActionContainer>
              )
            )}
          </LotCardContainer>

          <StyledLotCardSeparator />
        </LotCardMainContainer>
      )
    }
  ),
  isEqual // deep comparison of props
)

export type LotCardProps = AMWidgetLotCardProps | BidNowLotCardProps

export interface CommonLotCardProps {
  variant?: 'normal' | 'compact'
  lot: LotCardItemProps
  lotClickCallback?: () => void
  isShowingLotId: boolean
  isFollowButtonDisplayed?: boolean
  isBackTopShown?: boolean
  isUserPurchase?: boolean
  showPrice?: boolean
  lotTracking: { category: string }
}

interface AMWidgetLotCardProps extends CommonLotCardProps {
  showAMWidget: boolean
  showBidNow?: never
  showRegisterNow?: never
  userHasAnAddress?: never
}

interface BidNowLotCardProps extends CommonLotCardProps {
  showAMWidget?: never
  showBidNow: boolean
  showRegisterNow: boolean
  userHasAnAddress: boolean
}
